<template>
  <div class="relative">
    <div class="px-4 sm:px-6 md:px-0">
      <h1 class="text-3xl font-extrabold text-gray-900">Account Settings</h1>
    </div>
    <div class="px-4 sm:px-6 md:px-0">
      <div class="py-6">
        <div class="hidden lg:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex max-w-max gap-9" aria-label="Tabs">
              <router-link
                v-for="tab in tabs"
                :key="tab.name"
                :to="{ name: tab.route }"
                exact-active-class="border-blue-600 text-blue-600 hover:text-blue-600 hover:border-blue-500 font-medium"
                class="
                  border-transparent
                  text-gray-500
                  hover:text-gray-700
                  hover:border-gray-300
                  w-1/2
                  py-4
                  px-1
                  text-left
                  border-b-2
                  font-medium
                  text-sm
                "
              >
                {{ tab.name }}
              </router-link>
            </nav>
          </div>
        </div>
        <section class="mt-5">
          <router-view />
        </section>
      </div>
    </div>
  </div>
</template>


<script>
const tabs = [
  { name: "General", route: "settings-general" },
  { name: "Password", route: "settings-pwd" },
  { name: "Notification", route: "settings-notification" },
  { name: "Verification", route: "settings-verification" },
];

export default {
  setup() {
    return {
      tabs,
    };
  },
};
</script>